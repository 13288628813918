import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown, Card, Table, Badge, Col } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import { userManagementList } from "..//components/APIs/Api";
import Lottie from "lottie-react";
import { FaSearch } from "react-icons/fa"; // Import the search icon
import { RangeDatePicker } from "@y0c/react-datepicker";
import animationData from "..//../images/loding.json";
import dltIcon from "../../images/editi.svg";
import editIcon from "../../images/dlt.svg";
import Switch from "react-switch";
import DownloadReapoart from "../modal/DownloadRepoart";
import swal from "sweetalert";
import toast, { Toaster } from "react-hot-toast";
import { variable } from "../components/Variable";
import { blockUserApi } from "..//components/APIs/Api";
import defaultImg from "../../images/defaulImg.jpg";
import Pagination from "../components/Pagination";
// import { toast, ToastContainer } from "react-toastify";

import { clearFields } from "redux-form";
import { deleteUserApi } from "..//components/APIs/Api";
import FilterSearch from "../components/FilterSearch";
import moment from "moment";
export default function UserManagement(props) {
  const [loader, setLoader] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);
  const [usersList, setUsersList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [search, setSearch] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [reportModal, setReportModal] = useState(false);

  // const itemsPerPage = 5;
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const onImageError = (e) => {
    e.target.src = defaultImg;
  };
  const limitOptions = [
    { value: "5", label: "5" },

    { value: "10", label: "10" },
    { value: "20", label: "20" },
    { value: "40", label: "40" },
    { value: "60", label: "60" },
    { value: "80 ", label: "80 " },
    { value: "100", label: "100" },
  ];

  async function userListData() {
    setLoader(true);
    const res = await userManagementList(
      itemsPerPage,
      currentPage,
      search,
      startDate,
      endDate
    );
    setUsersList(res);
    setLoader(false);
  }

  async function hendleDeleteUser(id) {
    try {
      const res = await deleteUserApi(id);
      userListData();
    } catch (error) {
      console.error("Something bad happened");
      console.error(error.response);
      throw new Error(error);
    }
  }
  function onDateChange(...args) {
    console.log(args, "dated args");
    setStartDate(moment(args[0]).format("YYYY-MM-DD"));
    setEndDate(moment(args[1]).format("YYYY-MM-DD"));
  }

  console.log(startDate, "start", endDate, "date");

  function deleteUserPromise(id) {
    toast.promise(hendleDeleteUser(id), {
      loading: "Saving...",
      success: "Delete Successfully!",
      error: "try latter.",
    });
  }

  async function blockUser(id) {
    setLoader(true);
    try {
      const res = await blockUserApi(id);
      userListData();

      if (res?.data?.data === false) {
        toast.success("User Active");
      } else {
        toast.error("User Blocked");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoader(false);
    }
  }

  const totalItems = usersList?.data?.data.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    if (endDate?.length > 0) {
      scrollToTop(0);
      userListData();
    } else if (endDate?.length === 0) {
      userListData();
    }
  }, [endDate]);

  useEffect(() => {
    if (search.length > 0) {
      scrollToTop();
      userListData();
    } else if (search.length === 0) {
      userListData();
    }
  }, [search]);

  useEffect(() => {
    userListData();
    scrollToTop();
  }, [itemsPerPage]);

  useEffect(() => {
    userListData();
    scrollToTop();
  }, [currentPage]);

  useEffect(() => {
    userListData();
  }, []);

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  return (
    <>
      <div>
        <div className="d-flex justify-content-between align-items-center">
          <PageTitle activeMenu="Users List" motherMenu="Users" />

          <RangeDatePicker
            startText="Start"
            endText="End"
            startPlaceholder="Start Date"
            endPlaceholder="End Date"
            onChange={onDateChange}
          />
        </div>

        <Col>
          <div className="mt-4 d-flex justify-content-between align-items-center">
            <div
              className="form-group "
              style={{ position: "relative", width: "40%" }}
            >
              <input
                type="text"
                className="form-control"
                placeholder="Search..."
                // style={{ width: "40%" }}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <FaSearch className="search-icon" />
            </div>
            <div>
              <button
                className="btn btn-sm bTn"
                onClick={() => setReportModal(true)}
              >
                Download Report
              </button>
            </div>
          </div>
          {loader ? (
            <div>
              <Lottie
                style={{
                  height: "200px",
                  marginTop: "45px",
                  padding: "30px",
                }}
                animationData={animationData}
              />
            </div>
          ) : (
            <Card>
              {/* <h4>Lists</h4>
              <Card.Header></Card.Header> */}

              <Card.Body>
                <Table>
                  <thead style={{ color: "black" }}>
                    <tr>
                      {/* <th>
                        <strong>IMAGE</strong>
                      </th> */}
                      <th>
                        <strong>NAME</strong>
                      </th>
                      <th>
                        <strong>EMAIL</strong>
                      </th>
                      <th>
                        <strong>PHONE NUMBER</strong>
                      </th>
                      <th>
                        <strong>STATUS</strong>
                      </th>
                      {/* <th>
                        <strong>action</strong>
                      </th> */}
                      <th>
                        <strong>Action</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usersList?.data?.data?.users?.map((item, i) => (
                      <tr key={item.id}>
                        {console.log(usersList)}

                        {/* <td>
                          <img
                            height={60}
                            width={60}
                            src={item.image || defaultImg}
                            alt="image"
                            className="profile-photo"
                            onError={onImageError}
                          />
                        </td> */}
                        <td>
                          {item.firstName}
                          <span> {item.lastName}</span>
                        </td>
                        <td>{item.email}</td>
                        <td>{item.phoneNumber}</td>
                        {/* <td>
                          {!item?.isBlocked ? (
                            <Badge variant="success light">Active</Badge>
                          ) : (
                            <Badge variant="danger light">Blocked</Badge>
                          )}
                        </td> */}
                        <td>
                          <label>
                            <Switch
                              onChange={() => blockUser(item._id)}
                              checked={!item?.isBlocked}
                            />
                          </label>
                        </td>
                        {/* <td>{item?.status}</td> */}
                        <td>
                          <div class="d-flex" style={{ gap: "10px" }}>
                            <div
                              class="btn btn-primary shadow btn-xs sharp me-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                props.history.push({
                                  pathname: "/user-details",
                                  state: { item: item },
                                });
                              }}
                            >
                              <i class="fa fa-info-circle"></i>
                            </div>
                            <div
                              class="btn btn-danger shadow btn-xs sharp"
                              onClick={() => deleteUserPromise(item?._id)}
                              style={{ cursor: "pointer" }}
                            >
                              <i class="fa fa-trash"></i>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                {usersList?.data?.data?.users?.length === 0 && (
                  <div className="justify-content-center d-flex my-5 ">
                    Sorry, Data Not Found!
                  </div>
                )}
                {usersList?.length !== 0 && (
                  <div className="card-footer clearfix">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="dataTables_info">
                        Page {currentPage * itemsPerPage + 1} to{" "}
                        {totalItems > (currentPage + 1) * itemsPerPage
                          ? (currentPage + 1) * itemsPerPage
                          : totalItems}{" "}
                        of {totalItems} entries
                      </div>

                      <div
                        className="d-flex align-items-center"
                        style={{ gap: "10px" }}
                      >
                        <FilterSearch
                          FilterOption={limitOptions}
                          setFilterType={setItemsPerPage}
                          limitValue={itemsPerPage}
                        />
                        <Pagination
                          pageCount={totalPages}
                          pageValue={currentPage}
                          setPage={setCurrentPage}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Card.Body>
            </Card>
          )}
        </Col>
        {/* {loader && <Spinner />} */}
      </div>
      <DownloadReapoart
        show={reportModal}
        onHide={() => setReportModal(false)}
      />
    </>
  );
}
