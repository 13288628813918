import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
// import { viewUserDetails } from "../components/APIs/Api";
import { variable } from "../components/Variable";
import Lottie from "lottie-react";
import defaultImg from "../../images/defaulImg.jpg";
import animationData from "../../images/loding.json";

export default function UserDetail(props) {
  // const [UserDetails, setUserDetails] = useState();
  const [loading, setLoding] = useState(true);

  let userDetails = props?.location?.state?.item;
  console.log(userDetails);
  const onImageError = (e) => {
    e.target.src = defaultImg;
  };

  // async function viewUserDetailsApi() {
  //   const res = await viewUserDetails(variable.id);
  //   setUserDetails(res);
  //   setLoding(false);
  // }

  // useEffect(() => {
  //   viewUserDetailsApi();
  //   console.log(variable.id, "variableis");
  // }, []);

  // let item = UserDetails?.data?.data;
  // console.log(item, "userGHHGj");

  return (
    <>
      <div>
        <div className="page-titles">
          <h4>Users Details</h4>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="user-management">Users</Link>
            </li>
            <li className="breadcrumb-item active">
              <Link>Users Details</Link>
            </li>
          </ol>
        </div>
        <div>
          <Card style={{ backgroundColor: "transparent" }}>
            <Card.Body>
              <div className="text-black" style={{ width: "49%" }}>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="m-0">Name</h5>
                  <p className="m-0" style={{ color: "gray" }}>
                    {userDetails.firstName}
                    <span className="ml-1">{userDetails?.lastName}</span>
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="m-0">Email</h5>
                  <p className="m-0" style={{ color: "gray" }}>
                    <span className="ml-1">{userDetails?.email}</span>
                  </p>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="m-0">Phone</h5>
                  <p className="m-0" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {userDetails?.phoneNumber || "N/A"}
                    </span>
                  </p>
                </div>
              </div>{" "}
            </Card.Body>
          </Card>
        </div>
        <div className="d-flex" style={{ gap: "10px" }}>
          <Card style={{ width: "50%" }}>
            <Card.Header>
              <div
                className="d-flex text-black"
                style={{ gap: "1rem", flexGrow: "1" }}
              >
                Disability Information
              </div>
            </Card.Header>
            <Card.Body>
              <div className="text-black">
                <div>
                  <h5 className="m-0">1.Type of Disability</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {userDetails?.form?.disabilityInformation
                        ?.typeOfDisability || "N/A"}
                    </span>
                  </p>
                </div>
                <div>
                  <h5 className="m-0">2.Severity of Disability</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {" "}
                      {userDetails?.form?.disabilityInformation?.severity ||
                        "N/A"}
                    </span>
                  </p>
                </div>
                <div>
                  <h5 className="m-0">3.Onset of Disability</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {" "}
                      {userDetails?.form?.disabilityInformation
                        ?.onsetOfDisability || "N/A"}
                    </span>
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card style={{ width: "50%" }}>
            <Card.Header>
              <div
                className="d-flex text-black"
                style={{ gap: "1rem", flexGrow: "1" }}
              >
                Education / Occupation
              </div>
            </Card.Header>
            <Card.Body>
              <div className="text-black">
                <div>
                  <h5 className="m-0">1.Highest Education Level</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {userDetails?.form?.education?.highestEductionLevel ||
                        "N/A"}
                    </span>
                  </p>
                </div>
                <div>
                  <h5 className="m-0">2.Employment Status</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {userDetails?.form?.education?.employementStatus || "N/A"}
                    </span>
                  </p>
                </div>
                <div>
                  <h5 className="m-0">3.Education / Occupational needs</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {userDetails?.form?.education?.occupationalNeeds || "N/A"}
                    </span>
                  </p>
                </div>
              </div>{" "}
            </Card.Body>
          </Card>
        </div>
        <div className="d-flex" style={{ gap: "10px" }}>
          <Card style={{ width: "50%" }}>
            <Card.Header>
              <div
                className="d-flex text-black"
                style={{ gap: "1rem", flexGrow: "1" }}
              >
                Health Information
              </div>
            </Card.Header>
            <Card.Body>
              <div className="text-black">
                <div>
                  <h5 className="m-0">1.Ongoing medical treatment?</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {" "}
                      {userDetails?.form?.healthInformation?.medicalTreatment ||
                        "N/A"}
                    </span>
                  </p>
                </div>
                <div>
                  <h5 className="m-0">2.Health Insurance Provider</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {" "}
                      {userDetails?.form?.healthInformation
                        ?.insuranceProvider || "N/A"}
                    </span>
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card style={{ width: "50%" }}>
            <Card.Header>
              <div
                className="d-flex text-black"
                style={{ gap: "1rem", flexGrow: "1" }}
              >
                Support & Assistance
              </div>
            </Card.Header>
            <Card.Body>
              <div className="text-black">
                <div className="">
                  <h5 className="m-0">1.Assistance Received</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {" "}
                      {userDetails?.form?.supportAndAssistance
                        ?.assistanceReceived || "N/A"}
                    </span>
                  </p>
                </div>
                <div className="">
                  <h5 className="m-0">2.Assistive Devices</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {" "}
                      {userDetails?.form?.supportAndAssistance
                        ?.assistiveDevices || "N/A"}
                    </span>
                  </p>
                </div>
                <div className="">
                  <h5 className="m-0">3.Unmet Assistance Needs</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {" "}
                      {userDetails?.form?.supportAndAssistance
                        ?.unmetAssistanceNeeds || "N/A"}
                    </span>
                  </p>
                </div>
                <div className="">
                  <h5 className="m-0">4.Program Participation</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {" "}
                      {userDetails?.form?.supportAndAssistance
                        ?.programParticipation || "N/A"}
                    </span>
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
        <div className="d-flex" style={{ gap: "10px" }}>
          <Card style={{ width: "50%" }}>
            <Card.Header>
              <div
                className="d-flex text-black"
                style={{ gap: "1rem", flexGrow: "1" }}
              >
                Socioeconomic Information
              </div>
            </Card.Header>
            <Card.Body>
              <div className="text-black">
                <div>
                  <h5 className="m-0">1.Household Composition</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {userDetails?.form?.socioeconomicInformation
                        ?.houseHoldCompositon || "N/A"}
                    </span>
                  </p>
                </div>

                <div>
                  <h5 className="m-0">2.Living Conditions</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {userDetails?.form?.socioeconomicInformation
                        ?.livingConditions || "N/A"}
                    </span>
                  </p>
                </div>
                <div>
                  <h5 className="m-0">3.Household Income</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {userDetails?.form?.socioeconomicInformation
                        ?.houseHoldIncome || "N/A"}
                    </span>
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card style={{ width: "50%" }}>
            <Card.Header>
              <div
                className="d-flex text-black"
                style={{ gap: "1rem", flexGrow: "1" }}
              >
                Additional Information
              </div>
            </Card.Header>
            <Card.Body>
              <div className="text-black">
                <div>
                  <h5 className="m-0">1.support Contact Number</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {" "}
                      {userDetails?.form?.additionalInformation
                        ?.supportContactNumber || "N/A"}
                    </span>
                  </p>
                </div>

                <div>
                  <h5 className="m-0">2.Support Person</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {" "}
                      {userDetails?.form?.additionalInformation
                        ?.supportPerson || "N/A"}
                    </span>
                  </p>
                </div>
                <div>
                  <h5 className="m-0">2.Support Relationship</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {" "}
                      {userDetails?.form?.additionalInformation
                        ?.supportRelationship || "N/A"}
                    </span>
                  </p>
                </div>
                <div>
                  <h5 className="m-0">3.Support Email</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {" "}
                      {userDetails?.form?.additionalInformation?.supportEmail ||
                        "N/A"}
                    </span>
                  </p>
                </div>

                <div>
                  <h5 className="m-0">5.Email Address </h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {" "}
                      {userDetails?.form?.additionalInformation?.emailAddress ||
                        "N/A"}
                    </span>
                  </p>
                </div>
                <Card.Header style={{ padding: "5px" }}></Card.Header>
                <h4 className="mt-2" style={{ color: "gray" }}>
                  Emergency Contact Details
                </h4>

                <div className="mt-3">
                  <h5 className="m-0">1.Emergency Contact Name</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {" "}
                      {userDetails?.form?.additionalInformation
                        ?.emergencyContactName || "N/A"}
                    </span>
                  </p>
                </div>
                <div className="">
                  <h5 className="m-0">2.Relationship with Emergency Contact</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {" "}
                      {userDetails?.form?.additionalInformation
                        ?.relationshipToContact || "N/A"}
                    </span>
                  </p>
                </div>
                <div className="">
                  <h5 className="m-0">3.Emergency Contact Number</h5>
                  <p className="m-0 p-2" style={{ color: "gray" }}>
                    <span className="ml-1">
                      {" "}
                      {userDetails?.form?.additionalInformation
                        ?.emergencyContactNumber || "N/A"}
                    </span>
                  </p>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
}
