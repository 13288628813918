import React, { useState } from "react";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
// import { toast, ToastContainer } from "react-toastify";
import { toast } from "react-hot-toast";
import "./AddServiceProvider.css";
import { addSubAdminApi } from "../components/APIs/Api";

import "react-phone-input-2/lib/style.css";
export default function AddSubAdmin({ show, onHide, subAdminList }) {
  const initialObject = {
    userManagement: false,
    subAdminTab: false,
    privacyPolicy: false,
    // dashBoard: true,
  };
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [nameEroor, setNameError] = useState("");
  const [numberEroor, setNumberError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [permission, setPermission] = useState(initialObject);

  const [loader, setLoader] = useState(false);
  console.log(permission);

  const handleCheckboxChange = (key) => {
    setPermission((prevObject) => ({
      ...prevObject,
      [key]: !prevObject[key], // Toggle the value
    }));
  };

  async function hendelAddService(event) {
    event.preventDefault();
    let AddFormData = {
      name,
      email,
      phoneNumber: phone,
      password,
      Permissions: permission,
    };
    if (name.length < 3) {
      setNameError("Invalid name");
      return;
    } else if (email.length === 0) {
      setEmailError("Enter mail");
      return;
    } else if (name.length === 0) {
      setEmailError("Enter name");
      return;
    } else if (password.length < 6) {
      setPasswordError("password greaterthen 6");
      return;
    } else if (phone.length < 6) {
      setNumberError("number greaterthen 6");
      return;
    } else if (phone.length > 15) {
      setNumberError("number lessThen 15");
      return;
    }
    setLoader(true);
    try {
      const response = await addSubAdminApi(AddFormData);
      if (response.status === 200) {
        setLoader(false);
        onHide(false);
        subAdminList();
        toast.success("Added SubAdmin successful!");
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.data);
    }
  }
  return (
    <Modal className="modal fade" show={show} centered>
      <div className="">
        <form onSubmit={hendelAddService}>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Add SubAdmin</h4>
            <button type="button" className="close" onClick={() => onHide()}>
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <i className="flaticon-cancel-12 close"></i>
            <div className="add-contact-box">
              <div className="add-contact-content">
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Name</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      required="required"
                      placeholder="Enter name"
                      onChange={(e) => {
                        setName(e.target.value);
                        setNameError("");
                      }}
                    />
                    <span className="text-danger">{nameEroor}</span>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Email</label>
                  <div className="contact-name">
                    <input
                      type="email"
                      className="form-control"
                      required="required"
                      placeholder="Enter email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError("");
                      }}
                    />
                    <span className="text-danger">{emailError}</span>
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Phone</label>

                  <input
                    type="number"
                    required="required"
                    className="form-control"
                    autocomplete="off"
                    onChange={(e) => {
                      setPhone(e.target.value);
                      setNumberError("");
                    }}
                    placeholder="Enter Phone Number"
                  />
                  <span className="validation-text">{numberEroor}</span>
                </div>

                <div className="form-group">
                  <label className="text-black font-w500">Password</label>
                  <input
                    type="text"
                    className="form-control"
                    required="required"
                    placeholder="Enter password"
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError("");
                    }}
                  />
                  <span className="text-danger">{passwordError}</span>
                </div>
                <label className="text-black font-w500">
                  Permissions Access
                </label>

                <div
                  className="d-flex flex-wrap justify-content-evenly"
                  style={{ gap: "15px" }}
                >
                  {Object.keys(permission).map((key) => (
                    <div key={key} className="">
                      <input
                        style={{ cursor: "pointer" }}
                        type="checkbox"
                        checked={permission[key]}
                        onChange={() => handleCheckboxChange(key)}
                      />
                      <label className="pl-2"> {key}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer ">
            <button className="bTn btn btn-sm">
              {loader ? <Spinner animation="border" size="sm" /> : "Add"}
            </button>
          </div>
        </form>
      </div>
      {/* </div> */}
    </Modal>
  );
}
