import React, { useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import PageTitle from "../../layouts/PageTitle";
import toast, { Toaster } from "react-hot-toast";
import { policyApi } from "../../components/APIs/Api";
import Button from "../../components/Button";
// import { sendPolicy } from "../../services/AuthService";
export default function Policies() {
  const [policyText, setPolicyText] = useState("");
  const [language, setLanguage] = useState("");
  const [loader, setLoader] = useState(false);
  const handleChangeClinical = (content) => {
    setPolicyText(content);
  };

  async function handleChangePolicy(event) {
    event.preventDefault();
    setLoader(true);
    try {
      const response = await policyApi(policyText);
      console.log(response, "service data responce");
      if (response?.status === 200) {
        setLoader(false);
        toast.success("Policies Update successfully");
      } else if (!response?.status === 200) {
        throw new Error("response.message");
      }
    } catch (error) {
      setLoader(false);
      toast.error(`Error: ${error?.message}`);
    }
  }
  return (
    <div>
      <PageTitle
        activeMenu="Privacy and Data Protection Notice"
        motherMenu="Static Pages"
      />
      {/* <div className="d-flex justify-content-end mb-3">
        <select
          style={{
            color: "#7e7e7e",
            padding: " 10px",
            borderColor: "lightgrey",
            borderRadius: "6px",
          }}
          onChange={(e) => setLanguage(e.target.value)}
        >
          <option hidden>Select policy</option>
          <option value={"english"}>English Policy</option>
          <option value={"arabic"}>Arabic Policy</option>
        </select>
      </div> */}

      <Editor
        apiKey="ww3hvtgactwe1bp9yewjbmsw9jqia8ceiewktnygzdckvhfe"
        //initialValue={prescription}
        init={{
          height: 500,
          menubar: false,
          plugins: [
            "advlist autolink lists link image code charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code textcolor wordcount",
            "textarea",
            "textcolor",
            "forecolor backcolor",
          ],
          toolbar:
            "undo redo | formatselect | code |link | image | bold italic backcolor | alignleft aligncenter alignright alignjustify |  \n" +
            "bullist numlist outdent indent | textcolor | textarea | forecolor backcolor",
          content_style: "body { color: #000 }",
        }}
        onEditorChange={handleChangeClinical}
        name="prescription"
      />
      <div className="d-flex justify-content-end mt-4">
        <button
          className={`btn btn-sm btn-primary ${loader ? "disabled" : ""}`}
          disabled={loader}
          style={
            loader
              ? { pointerEvents: "none", opacity: 0.5 }
              : { pointerEvents: "auto" }
          }
          onClick={(e) => {
            handleChangePolicy(e);
          }}
        >
          {loader ? (
            <div
              className="d-flex align-items-center justify-content-center "
              style={{ gap: "5px" }}
            >
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
              Loading...
            </div>
          ) : (
            "Send"
          )}
        </button>
      </div>
    </div>
  );
}
