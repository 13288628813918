import React, { useState, useEffect } from "react";
import { Modal, Card, Spinner } from "react-bootstrap";
import { downloadReportApi } from "../components/APIs/Api";
import toast from "react-hot-toast";

export default function AddSubAdmin({ show, onHide, subAdminList }) {
  const [reportUrl, setReportUrl] = useState();
  const [loader, setLoader] = useState(false);
  const checkboxGroups = {
    UserDetails: {
      FirstName: false,
      LastName: false,
      PhoneNumber: false,
      Email: false,
    },
    Disability: {
      TypeOfDisability: false,
      SeverityOfDisability: false,
      OnsetOfDisability: false,
    },
    EducationOccupation: {
      HighestEducationLevel: false,
      EmploymentStatus: false,
      EducationOccupationalNeeds: false,
    },
    HealthInformation: {
      OngoingMedicalTreatment: false,
      HealthInsuranceProvider: false,
    },
    SocioeconomicInformation: {
      HouseholdComposition: false,
      LivingConditions: false,
      HouseholdIncome: false,
    },
    SupportAssistance: {
      AssistanceReceived: false,
      AssistiveDevices: false,
      UnmetAssistanceNeeds: false,
      ProgramParticipation: false,
    },
    AdditionalInformation: {
      SupportPerson: false,
      SupportRelationship: false,
      SupportContactNumber: false,
      SupportEmail: false,
      EmergencyContactName: false,
      RelationshipToContact: false,
      EmergencyContactNumber: false,
      EmailAddress: false,
    },
  };
  //   baseURL
  const [selectAll, setSelectAll] = useState(false);
  const [checkboxState, setCheckboxState] = useState(checkboxGroups);

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    const updatedCheckboxState = Object.keys(checkboxState).reduce(
      (acc, group) => {
        acc[group] = Object.keys(checkboxState[group]).reduce(
          (groupAcc, key) => {
            groupAcc[key] = !selectAll;
            return groupAcc;
          },
          {}
        );
        return acc;
      },
      {}
    );
    setCheckboxState(updatedCheckboxState);
  };

  const handleCheckboxChange = (group, key) => {
    setCheckboxState((prevCheckboxState) => ({
      ...prevCheckboxState,
      [group]: {
        ...prevCheckboxState[group],
        [key]: !prevCheckboxState[group][key],
      },
    }));
  };
  //   console.log(checkboxGroups);

  const handleDownloadReport = async () => {
    setLoader(true);
    try {
      const response = await downloadReportApi(checkboxState);
      setLoader(false);
      setReportUrl(response);
    } catch (err) {
      setLoader(false);
      toast.error(err.response?.data?.message);
    }
  };

  const downloadFile = () => {
    if (reportUrl) {
      // Create a link element and trigger the download
      const link = document.createElement("a");
      link.href = reportUrl;
      link.setAttribute("download", "report.pdf");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  useEffect(() => {
    downloadFile();
  }, [reportUrl]);
  // Rest of your code...
  console.log("Export ", reportUrl);
  return (
    <Modal className="modal fade report-modal" show={show} centered>
      <div className="modal-header">
        <h4 className="modal-title fs-20">Select Report Fields</h4>
        <button type="button" className="close" onClick={() => onHide()}>
          <span>×</span>
        </button>
      </div>
      <Card style={{ marginBottom: "0" }}>
        {/* Select All Checkbox */}
        <div
          className="d-flex text-black p-3 justify-content-end align-content-center"
          style={{ gap: "1rem", flexGrow: "1" }}
        >
          <input
            style={{ cursor: "pointer", height: "16px", marginTop: "5px" }}
            type="checkbox"
            checked={selectAll}
            onChange={handleSelectAllChange}
          />
          <label className=""> Select All</label>
        </div>

        {/* Render Checkbox Groups */}
        {Object.keys(checkboxGroups).map((group) => (
          <React.Fragment key={group}>
            <div
              className="d-flex text-black pl-3 mt-3"
              style={{ gap: "1rem", flexGrow: "1" }}
            >
              {group}
            </div>
            <Card.Body>
              <div className="">
                <div
                  className="d-flex flex-wrap justify-content-evenly"
                  style={{ gap: "15px" }}
                >
                  {Object.keys(checkboxGroups[group]).map((key) => (
                    <div key={key} className="">
                      <input
                        style={{ cursor: "pointer" }}
                        type="checkbox"
                        checked={checkboxState[group][key]}
                        onChange={() => handleCheckboxChange(group, key)}
                      />
                      <label className="pl-2"> {key}</label>
                    </div>
                  ))}
                </div>
              </div>
            </Card.Body>
            {/* <Card.Header> </Card.Header> */}
          </React.Fragment>
        ))}
        <div className="d-flex justify-content-end p-3 mb-3">
          <button
            className="btn bTn btn-sm w-40"
            onClick={() => handleDownloadReport()}
          >
            {loader ? (
              <div>
                <Spinner animation="border" size="sm" />
                Download Now
              </div>
            ) : (
              "Download Now"
            )}
          </button>
        </div>
      </Card>
    </Modal>
  );
}
