import React from "react";

const Footer = () => {
  return (
    <div className="footer">
      <div className="copyright">
        <p>
          © Department of Youth, Social Development, and Seniors–
          <a>Government of Bermuda.</a>2024, All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
