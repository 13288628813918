import React, { useState } from "react";
import { Dropdown, Modal, Spinner } from "react-bootstrap";
import { toast, ToastContainer } from "react-hot-toast";
import "./AddServiceProvider.css";
import { editSubAdminApi } from "../components/APIs/Api";

import "react-phone-input-2/lib/style.css";
export default function EditSubAdmin({ show, onHide, subAdminList, list }) {
  const [name, setName] = useState(list?.name);
  const [email, setEmail] = useState(list?.email);
  const [phone, setPhone] = useState(list?.phoneNumber);
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);

  console.log(list);
  async function handleEditAdmin(event) {
    event.preventDefault();
    let serviceAddFormData = {
      name,
      email,
      password,
      phoneNumber: phone,
      id: list?._id,
    };

    setLoader(true);
    try {
      const response = await editSubAdminApi(serviceAddFormData);
      if (response?.status === 200) {
        setLoader(false);
        onHide(false);
        subAdminList();
        toast.success("Edit SubAdmin successful!");
      }
    } catch (error) {
      setLoader(false);
      toast.error(error?.response?.data?.data);
    }
  }
  return (
    <Modal className="modal fade" show={show} centered>
      <div className="">
        <form onSubmit={handleEditAdmin}>
          <div className="modal-header">
            <h4 className="modal-title fs-20">Update SubAdmin</h4>
            <button type="button" className="close" onClick={() => onHide()}>
              <span>×</span>
            </button>
          </div>
          <div className="modal-body">
            <i className="flaticon-cancel-12 close"></i>
            <div className="add-contact-box">
              <div className="add-contact-content">
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Name</label>
                  <div className="contact-name">
                    <input
                      type="text"
                      className="form-control"
                      value={name}
                      placeholder="Enter name"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Email</label>
                  <div className="contact-name">
                    <input
                      type="email"
                      className="form-control"
                      value={email}
                      placeholder="Enter email"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="text-black font-w500">Phone</label>

                  <input
                    type="number"
                    className="form-control"
                    value={phone}
                    autocomplete="off"
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Enter Phone Number"
                  />
                </div>

                <div className="form-group">
                  <label className="text-black font-w500">Password</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer ">
            <button className="bTn btn btn-sm">
              {loader ? <Spinner animation="border" size="sm" /> : "Update"}
            </button>
          </div>
        </form>
      </div>
      {/* </div> */}
    </Modal>
  );
}
